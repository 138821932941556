<template>
  <div>
    <section id="header" class="logo-bg">
      <div class="logo inner-wrapper">
        <a href="https://blueberrylabs.xyz">
          <h2>Blueberry Labs</h2>
        </a>
        <h4>Metaverse | Web3 | Discord Bot Development</h4>
        <!--<img src="/images/logo.png" id="logo" alt="blueberry labs logo"/>-->
      </div>
    </section>
    <!--
    <section class="green">
      <div class="inner-wrapper">
        <div id="navigation">
          <ul>
            <li><a href="https://cubes.wtf/mint">MINT NOW!</a></li>
            <li><a href="https://cubes.wtf/#about">About</a></li>
            <li><a href="https://cubes.wtf/#roadmap">Roadmap</a></li>
            <li><a href="https://cubes.wtf/#team">Team</a></li>
            <li><a href="https://cubes.wtf/kitchen">Test Kitchen</a></li>
          </ul>
        </div>
      </div>
    </section>
    -->
 </div>
</template>

<script>
export default {
  name: 'HeaderBlock',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header img {
  width: 100%;
  height: 100%;
}
#logo {
  max-width: 1400px;
  width:100%;
  height: auto;
  border-radius: 10px;
}

#navigation {
  list-style:none;
  margin:0;
  padding:0;
  text-align:center;
  height: auto;
}


#navigation li{
  display:inline;
  text-align: center;
}

#navigation a{
  display:inline-block;
  padding:10px 50px;
}
.logo-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 880px) {
  #navigation {
    text-align:center;
    height: auto;
  }
  #navigation li {
    display:revert;
    text-align: center;
    list-style: none;
    width:100%;
    padding: 5px 0;
    margin:0;
    border-bottom: 1px solid white;
  }
  #navigation li:last-of-type {
    border: none;
  }
  #navigation ul {
    margin-left:-40px;
  }
  #navigation a {
    display:revert;
    padding:15px 50px;
  }
}

#header h1 {
  text-align: center;
  width: 100%;
}

#nav a {
  font-weight: bold;
  color: #ffffff;
}
</style>
