<template>
  <footer class="inner-wrapper">
    &copy; 2022 Blueberry Labs
    <!--
    &nbsp;&nbsp;<a href="https://discord.gg/UrNNbYQ9pB" target="_blank"><img class="social discord" src="/images/socials/discord.svg" alt="Join our Discord!"></a>
      <a href="https://twitter.com/meatcubesnft" target="_blank"><img class="social twitter" src="/images/socials/twitter.svg" alt="Follow us on Twitter!"></a>
    -->
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  footer {
    text-align: center;
    color: #0097ff;
    font-weight: 600;
  }
  .social {
    max-width: 40px;
    display: inline-block;
  }
  .social {
    padding: 3px;
    position: relative;
    top: 8px;
  }
  .twitter {
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }
  .discord {
    filter: invert(50%) sepia(100%) saturate(100%) hue-rotate(20deg) brightness(100%) contrast(50%);
  }
</style>
