<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted: function()
  {
    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    }
  }
}
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}
/* Below are main variables to easily control different attributes of the page */
:root {
  --bg-color: rgb(255, 255, 255);
  --box-color: rgb(80, 204, 80);
  --mint-me-button-color: rgb(82, 158, 82);
  --mint-me-button-alternating-color: green;
  --main-text-color: rgb(38, 112, 38);
  --typewriter-text-color: rgb(38, 112, 38);
  --first-gradient-color: rgb(82, 158, 82);
  --second-gradient-color: rgb(38, 112, 38);
  --third-gradient-color: green;
  --on-gradient-text-color: white;
  --accordion-button-color: rgb(18, 90, 18);
}
html {
  height: 100%;
  line-height: 135%;
}
body {
  font-family: 'Helvetica', 'Arial', sans-serif, monospace; /* Change font family here */
  overflow-x: hidden;
  font-weight: normal;
  font-size: 26px;
  line-height: 1.5;
  background-color: #0097ff;
  height: 100%;
  width:100%;
  background-image: url("../public/images/bg.png");
  background-repeat: repeat;
}
a {
  color: #0097ff;
  text-decoration: underline;
  font-weight: 400;
  font-size:1em;
}
a:hover {
  color: #0066ff;
  cursor: pointer;
}
p {
  font-size: 1em;
}

h2 {
  color: #0097ff;
}

ul {
  list-style: none;
  margin-left:-30px;
}
h3 {
  margin-bottom:10px;
}
section {
  width: 100%;
}
.inner-padding {
  padding:5px;
}

.inner-wrapper {
  max-width: 1400px;
  margin-left:auto;
  margin-right:auto;
}
.flex-grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

#app {
  width:100%;
}

::-moz-selection { /* Code for Firefox */
  color: pink;
  background: darkslateblue;
}

::selection {
  color: pink;
  background: darkslateblue;
}

h1,h2,h3,h4,h5 {
  margin:0;
  padding:0;
}

div.wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  text-align: center;
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
  max-width: 1100px;
  border: 1px solid black;
  box-shadow: black;
}

#footer {
  color: white;
  font-size: 28px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: black;
}

#footer a {
  color: white;
  font-size: 28px;
}
</style>
