<template>
  <div class="bg-color">
    <div class="wrapper">
      <header-block></header-block><br />
      <section id="copy">
        <p>
          Welcome to Blueberry Labs! Our new website is a work in progress.<br />
          In the meantime checkout some of our recent work below!<br /><br />
          If you are looking for help with your next Web3 or NFT Project reach out to us!
        </p>
        <br />
        <a href="https://discord.gg/UrNNbYQ9pB" target="_blank">Blueberry Labs Discord</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="mailto:info@blueberrylabs.xyz">info@blueberrylabs.xyz</a>
        <br /><br />
        <h3>Our Recent Projects</h3><br />
        <ul>
          <li><a href="https://www.cubes.wtf" target="_blank">MeatCubes</a></li>
          <li><a href="https://www.nuggs.wtf" target="_blank">Nuggs.wtf</a></li>
          <li><a href="https://www.apespace.club" target="_blank">ApeSpace</a></li>
        </ul><br />
        Also be sure to <a href="https://www.cryptovoxels.com/parcels/5291" target="_blank">visit our new headquarters
        in Cryptovoxels</a><br /> on the beautiful island of Satoshi!
      </section><br />
      <footer-block></footer-block>
    </div>

  </div>
</template>
<script>
// @ is an alias to /src
import FooterBlock from "@/components/Footer-Block";
import HeaderBlock from "@/components/Header";
export default {
  name: 'Home',
  components: {
    HeaderBlock,
    FooterBlock
  }
}
</script>

<style scoped>
  #copy {
    font-size: 1em;
  }

  @media (max-width: 1000px) {
    .flex-grid {
      grid-template-columns: 100%;
    }
  }
</style>